// import { useEffect } from 'react'
import { constants, ethers } from 'ethers'
import {
  useEthers,
  useContractCall,
  useContractFunction,
  useContractCalls,
} from '@usedapp/core'

import { Contract } from '@ethersproject/contracts'

import ReferralContract from '../chain-info/contracts/ReferralContract.json'
import networkMapping from '../network-config'

const { abi: abiReferral } = ReferralContract
const referralContractInterface = new ethers.utils.Interface(abiReferral)

export function useGetJoinersAddress(programDetail, contractAddress) {
  const [addrList] =
    useContractCall(
      programDetail.code &&
        programDetail.code !== '' &&
        contractAddress && {
          abi: referralContractInterface,
          address: contractAddress,
          method: 'getJoinersAddress',
          args: [programDetail.code],
        }
    ) ?? []

  return addrList
}
export function useGetReferees(
  programDetail,
  contractAddress,
  referrerAddress,
  openUsers
) {
  const [addrList] =
    useContractCall(
      openUsers &&
        programDetail.code &&
        programDetail.code !== '' &&
        referrerAddress &&
        contractAddress && {
          abi: referralContractInterface,
          address: contractAddress,
          method: 'getJoinerRefereesL1Address',
          args: [programDetail.code, referrerAddress],
        }
    ) ?? []

  return addrList
}
export function useGetUidFromAddress(
  programDetail,
  contractAddress,
  userAddress
) {
  const [uid] =
    useContractCall(
      userAddress !== '' &&
        programDetail.code &&
        programDetail.code !== '' &&
        contractAddress && {
          abi: referralContractInterface,
          address: contractAddress,
          method: 'addressJoined',
          args: [programDetail.code, userAddress],
        }
    ) ?? []

  return uid
}

export function useGetJoinersAddressByArray(
  programDetail,
  from_to,
  contractAddress
) {
  return useContractCalls(
    programDetail.code &&
      programDetail.code !== '' &&
      from_to &&
      contractAddress
      ? from_to.map(i => ({
          abi: referralContractInterface,
          address: contractAddress,
          method: 'joinersAddress',
          args: [programDetail.code, i],
        }))
      : []
  )
}
export function useGetUidJoiner(
  programDetail,
  joiners,
  contractAddress,
  openUsers
) {
  return useContractCalls(
    openUsers &&
      programDetail.code &&
      programDetail.code !== '' &&
      joiners &&
      joiners.length > 0 &&
      joiners[0] &&
      contractAddress
      ? joiners.map(addr => ({
          abi: referralContractInterface,
          address: contractAddress,
          method: 'addressJoined',
          args: [programDetail.code, addr],
        }))
      : []
  )
}

export function useGetCounterLevel1(
  openUsers,
  programDetail,
  joiners,
  contractAddress
) {
  return useContractCalls(
    openUsers &&
      programDetail.code &&
      programDetail.code !== '' &&
      joiners &&
      joiners.length > 0 &&
      typeof joiners[0] !== 'undefined' &&
      contractAddress
      ? joiners.map(addr => ({
          abi: referralContractInterface,
          address: contractAddress,
          method: 'getTotalRefereesL1',
          args: [programDetail.code, addr],
        }))
      : []
  )
}

export function useGetRefereesLevel1L2(
  programDetail,
  joiners,
  contractAddress
) {
  const result =
    useContractCalls(
      programDetail.code &&
        programDetail.code !== '' &&
        joiners &&
        joiners.length > 0 &&
        typeof joiners[0] !== 'undefined' &&
        contractAddress
        ? joiners.map(addr => ({
            abi: referralContractInterface,
            address: contractAddress,
            method: 'getTotalRefereesL1L2',
            args: [programDetail.code, addr],
          }))
        : []
    ) ?? []

  var returnResult = {}
  for (var i = 0; i < result.length; i++) {
    returnResult[joiners[i]] = result[i] ?? 0
  }

  return returnResult
}

export function useGetRefereesLevel1L2_temp(
  programDetail,
  joiners,
  contractAddress
) {
  const result =
    useContractCalls(
      programDetail.code &&
        programDetail.code !== '' &&
        joiners &&
        joiners.length > 0 &&
        typeof joiners[0] !== 'undefined' &&
        contractAddress
        ? joiners.map(addr => ({
            abi: referralContractInterface,
            address: contractAddress,
            method: 'getTotalRefereesL1L2',
            args: [programDetail.code, addr],
          }))
        : []
    ) ?? []

  return result
}

export function useGetRefereesLevel1(programDetail, joiners, contractAddress) {
  const result =
    useContractCalls(
      programDetail.code &&
        programDetail.code !== '' &&
        joiners &&
        joiners.length > 0 &&
        typeof joiners[0] !== 'undefined' &&
        contractAddress
        ? joiners.map(addr => ({
            abi: referralContractInterface,
            address: contractAddress,
            method: 'getTotalRefereesL1',
            args: [programDetail.code, addr],
          }))
        : []
    ) ?? []

  var returnResult = {}
  for (var i = 0; i < result.length; i++) {
    returnResult[joiners[i]] = result[i] ?? 0
  }

  return returnResult
}
export function useGetRefereesLevel2(programDetail, joiners, contractAddress) {
  const holdersL1 = []
  for (const addr in joiners) {
    if (joiners[addr] > 0) holdersL1.push(addr)
  }
  const result = useContractCalls(
    programDetail.code &&
      programDetail.code !== '' &&
      holdersL1 &&
      holdersL1.length > 0 &&
      typeof holdersL1[0] !== 'undefined' &&
      contractAddress
      ? holdersL1.map((addr, index) => {
          return {
            abi: referralContractInterface,
            address: contractAddress,
            method: 'getTotalRefereesL2',
            args: [programDetail.code, addr],
          }
        })
      : []
  )

  var returnResult = {}
  for (var i = 0; i < result.length; i++) {
    returnResult[holdersL1[i]] = result[i] ?? 0
  }

  return returnResult
}
/* export function useGetRefereesLevel2(
  openUsers,
  programDetail,
  joiners,
  refereesLevel1Joiners,
  contractAddress
) {
  return useContractCalls(
    openUsers &&
      programDetail.code &&
      programDetail.code !== '' &&
      joiners &&
      joiners.length > 0 &&
      typeof joiners[0] !== 'undefined' &&
      contractAddress
      ? joiners.map((addr, index) => {
          if (refereesLevel1Joiners[index] > 0)
            return {
              abi: referralContractInterface,
              address: contractAddress,
              method: 'getTotalRefereesL2',
              args: [programDetail.code, addr],
            }
        })
      : []
  )
} */

export function useJoined(uid, contractAddress) {
  const [addressJoined] =
    useContractCall(
      contractAddress &&
        uid && {
          abi: referralContractInterface,
          address: contractAddress,
          method: 'userJoined',
          args: [uid],
        }
    ) ?? []

  return addressJoined
}

export function useCheckJoin(programDetail, uid, contractAddress) {
  const [joined] =
    useContractCall(
      uid &&
        programDetail.code &&
        programDetail.code !== '' &&
        contractAddress && {
          abi: referralContractInterface,
          address: contractAddress,
          method: 'uidJoined',
          args: [programDetail.code, uid],
        }
    ) ?? []

  return joined
}

export function useContractMethodSingle(methodName) {
  const { chainId } = useEthers()
  const contractAddress = chainId
    ? networkMapping[String(chainId)]['referral_contract']
    : constants.AddressZero
  const contract = new Contract(contractAddress, referralContractInterface)

  const { state, send } = useContractFunction(contract, methodName)

  return { state, send }
}
