import React from 'react'
// import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Container } from '@mui/material'

import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'

import { Header } from './components/Header'
import { Main } from './components/Main'
import { About } from './components/About'
import './App.css'

const queryClient = new QueryClient()

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Header />
          <Container maxWidth="lg">
            <Routes>
              <Route exact path="/" element={<Main />} />
              <Route path="/home" element={<Main />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </Container>
        </div>
        {/* <ReactQueryDevtools initialIsOpen /> */}
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App
