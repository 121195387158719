import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'

import {
  Box,
  Button,
  Snackbar,
  Card,
  Typography,
  CardActions,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  InputBase,
  IconButton,
  Chip,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'

import { styled, alpha } from '@mui/material/styles'
import axios from 'axios'
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'

import DownloadIcon from '@mui/icons-material/Download'
import FactCheckIcon from '@mui/icons-material/FactCheck'

import SearchIcon from '@mui/icons-material/Search'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import { makeStyles } from '@mui/styles'
import { useEthers } from '@usedapp/core'
import { formatUnits, formatEther, parseEther } from '@ethersproject/units'
import { BigNumber } from '@ethersproject/bignumber'
// import md5 from 'md5'

import { WalletBalance } from './WalletBalance'
import { ContractBalance } from './ContractBalance'

import config from '../../network-config'
import {
  useGetTotalUser,
  // useGetTotalIncentive,
  useGetAddressJoiner,
  useGetJoinerReferees,
  // useGetHoldersTotal,
} from '../../hooks'

import {
  useGetJoinersAddress,
  useGetJoinersAddressByArray,
  useGetReferees,
  useGetUidJoiner,
  useGetUidFromAddress,
  useJoined,
  useCheckJoin,
  useContractMethodSingle,
  useGetRefereesLevel1,
  useGetRefereesLevel2,
  useGetCounterLevel1,
  useGetRefereesLevel1L2,
  useGetRefereesLevel1L2_temp,
} from '../../hooks/referralContract'
import {
  useContractMethod,
  useGetProgram,
  useCallAdmin,
  useGetHoldersTotal,
  useGetIncentiveHolder,
  useGetIncentive,
  useGetUsersIncentive,
  useGetUsersDeny,
  useGetReferralInfo,
} from '../../hooks/referralAdminContract'

const useStyles = makeStyles({
  tabContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: 24,
  },
  boxMain: {
    paddingBottom: 20,
  },
  box: {
    padding: 16,
    marginTop: 20,
    backgroundColor: 'white',
    borderRadius: '25px',
  },
  header: {
    color: '#000',
  },
  rightAlignItem: {
    justifyContent: 'end',
  },
  uid: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})

/* const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
    •
  </Box>
) */

export const YourWallet = ({ supportedTokens }) => {
  const { chainId, account } = useEthers()
  const [alertMessage, setAlertMessage] = React.useState('')

  const queryClient = useQueryClient()

  const contractAdminAddress = chainId
    ? config[chainId]['referral_admin_contract']
    : ''
  const contractReferralAddress = chainId
    ? config[chainId]['referral_contract']
    : ''
  // const cookies = new Cookies();

  /* const makeUid = (length) => {
        var result           = '';
        var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() *charactersLength));
        }
        return result;
    } */

  // var myUid = account ? md5(account).substr(3, 15) : ''

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setAlertMessage('')
  }

  const [limitPage, setLimitPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)

  const [programCode, setProgramCode] = useState('')
  const [holders, setHolders] = useState([])
  const [totalHolders, setTotalHolders] = React.useState(0)

  const [myUid, setMyUid] = useState('')
  const [myUidReferralCode, setMyUidReferralCode] = useState('')

  const handleChangeLimit = event => {
    setLimitPage(event.target.value)
    setHolders([])
    setCurrentPage(0)
    remove()
  }
  const handleNextPage = () => {
    setHolders([])
    const page = currentPage + 1
    setCurrentPage(page)
    refetch()
  }
  const handlePrevPage = () => {
    setHolders([])
    const page = currentPage - 1
    setCurrentPage(page)
    refetch()
  }
  const handleReset = () => {
    setHolders([])
    setCurrentPage(0)
    remove()
  }

  /* var addressJoined = useJoined(myUid, contractReferralAddress)
  var joined =
    addressJoined &&
    addressJoined !== '0x0000000000000000000000000000000000000000' */
  var joined = false

  const classes = useStyles()

  /* useEffect(() => {
    if (programCode) {
      console.log(programCode)
    }
  }, [programCode]) */

  const allowJoinProgram = false

  const { state: approveAllState, send: approveAll } = useContractMethod(
    'approveIncentive'
  )
  const { state: denyState, send: denyAddress } = useContractMethod(
    'denyAddress'
  )
  /* const { state: releaseState, send: releaseIncentive } = useContractMethod(
    'releaseAddress'
  ) */

  useEffect(() => {
    if (
      approveAllState.status === 'Success' ||
      denyState.status === 'Success'
    ) {
      setAlertMessage('Success')
      setOpenConfirm(false)
      setUserAddress('')
      // setRows([])

      // Close confirm approveAll
      setOpenConfirmApprove(false)
    } else if (denyState.status === 'Exception') {
      setAlertMessage(denyState.errorMessage)
    } else if (approveAllState.status === 'Exception') {
      setAlertMessage(approveAllState.errorMessage)
    }
  }, [denyState, approveAllState])

  const programDetail = useGetProgram(programCode, contractAdminAddress)

  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [openConfirmApprove, setOpenConfirmApprove] = React.useState(false)
  const [openUsers, setOpenUsers] = React.useState(false)
  const [allowApproveMetaMask, setAllowApproveMetaMask] = React.useState(false)
  const [allowDenyMetaMask, setAllowDenyMetaMask] = React.useState(false)

  const [calculatePage, setCalculatePage] = React.useState(0)

  const [userAddress, setUserAddress] = React.useState('')
  // const [userIndex, setUserIndex] = React.useState(0)
  const isAdmin = useCallAdmin('admins', contractAdminAddress, [account])

  /* const joinersAddress = useGetJoinersAddress(
    programDetail,
    contractReferralAddress
  ) */

  const denyAddressCall = async obj => {
    console.log(obj)

    const { data: response } = await axios.post(
      `${config.apiUrl}/v1/admin/deny`,
      {
        code: obj.code,
        address: obj.address,
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
    return response
  }
  const { mutate: mutateDeny, isLoading: isLoadingDeny } = useMutation(
    denyAddressCall,
    {
      onSuccess: data => {
        setAllowDenyMetaMask(true)
      },
      onError: () => {
        alert('there was an error')
      },
      onSettled: () => {
        queryClient.invalidateQueries('deny')
      },
    }
  )

  const handleDeny = () => {
    mutateDeny({ code: programDetail.code, address: userAddress })
  }

  const commitAllApi = async (
    _programCode,
    _addressesList,
    _remainAmountList
  ) => {
    var addressesList = []
    for (var i = 0; i < holders.length; i++) {
      addressesList.push(holders[i].address)
    }

    const { data: response } = await axios.post(
      `${config.apiUrl}/v1/admin/commit`,
      {
        code: _programCode,
        addresses: addressesList,
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
    return response
  }
  const { mutate: mutateCommit, isLoading: isLoadingCommit } = useMutation(
    commitAllApi,
    {
      onSuccess: data => {
        setAllowApproveMetaMask(true)
      },
      onError: () => {
        alert('there was an error')
      },
      onSettled: () => {
        queryClient.invalidateQueries('commit')
      },
    }
  )

  const handleCommit = () => {
    if (holders.length > 0) {
      var addressesList = []
      var remainAmountList = []
      for (var i = 0; i < holders.length; i++) {
        addressesList.push(holders[i].address)
        remainAmountList.push(
          parseEther(holders[i].remainAmount.toString(), 18)
        )
      }
      mutateCommit(programCode, addressesList, remainAmountList)
    }
  }
  const handleApprovalMetamask = () => {
    if (holders.length > 0) {
      var addressesList = []
      var l1List = []
      var l2List = []
      for (var i = 0; i < holders.length; i++) {
        addressesList.push(holders[i].address)
        l1List.push(holders[i].refL1)
        l2List.push(holders[i].refL2)
      }
      approveAll(programCode, addressesList, l1List, l2List)
    }
  }

  const handleClickOpenConfirm = address => () => {
    setUserAddress(address)
    // setUserIndex(index)
    setOpenConfirm(true)
  }

  const handleConfirmDenyMetamask = () => {
    denyAddress(programDetail.code, userAddress)
  }
  const handleCloseConfirm = () => {
    setOpenConfirm(false)
    setUserAddress('')
    // setUserIndex(0)
  }
  const handleCloseConfirmApprove = () => {
    setOpenConfirmApprove(false)
    setAllowApproveMetaMask(false)
  }
  const handleClickOpenConfirmApprove = () => {
    setOpenConfirmApprove(true)
    setAllowApproveMetaMask(false)
  }
  const handleClickCloseListUser = () => {
    setOpenUsers(false)
    setUserAddress('')
  }
  const handleClickOpenListUser = _addr => {
    setUserAddress(_addr)
    setOpenUsers(true)
  }

  const handleStartCalculate = () => {
    // setRows([])
    setHolders([])
    setCalculatePage(1)
  }
  const totalIncentiveRemain = holders
    .map(item => parseFloat(item.remainAmount))
    .reduce((result, remain) => {
      const total = result + remain
      return total
    }, 0)
    .toFixed(4)

  const getReferrers = async _code => {
    const offset = currentPage * limitPage
    const { data } = await axios.get(
      `${config.apiUrl}/v1/admin/joiners/${_code}?offset=${offset}&limit=${limitPage}&incentive=1&is_banned=0`
    )
    return data
  }

  function useGetReferrers(_code) {
    return useQuery('referrers', () => getReferrers(_code), {
      enabled: false,
    })
  }

  const getRefereesOfReferrer = async (_code, _address) => {
    const { data } = await axios.get(
      `${config.apiUrl}/v1/admin/joiners/${_code}?offset=0&limit=200&address_referral=${_address}&order_by=transactions`
    )
    return data
  }

  function useGetRefereersOfReferrer(_code, _address) {
    return useQuery(
      ['refereersOfReferrer', _code],
      () => getRefereesOfReferrer(_code, _address),
      {
        enabled: !!_code && !!_address && openUsers,
      }
    )
  }

  const { status, data, error, isFetching, refetch, remove } = useGetReferrers(
    programCode
  )

  const { data: refereesAddress } = useGetRefereersOfReferrer(
    programCode,
    userAddress
  )
  useEffect(() => {
    if (data) setTotalHolders(data.data.total)

    if (data && data.data.items.length > 0) {
      var holdersArray = []
      data.data.items.map((item, index) => {
        if (item.incentiveRemain > 0) {
          holdersArray.push({
            uid: item.uid,
            address: item.address,
            incentivePaid: item.incentivePaid,
            remainAmount: item.incentiveRemain,
            totalIncentive:
              parseFloat(item.incentiveRemain) +
              parseFloat(item.incentiveRemain),
            refL1: item.refereesL1,
            refL2: item.refereesL2,
            banned: item.is_banned,
            totalTransactions: item.total_transactions,
            balanceOf: item.balanceOf,
            pointValid: item.point_valid,
          })
        }
      })
      setHolders(holdersArray)
    } else {
      setHolders([])
      setTotalHolders(0)
    }
  }, [data, currentPage])
  // Start 16967
  /* const from_to = Array.from(
    Array(3).keys(),
    x => x + 16967 + 200 + 400 * 7 + 100 * 2 + 50 + 5 + 5 + 5 + 3
  )
  const joinersAddress = useGetJoinersAddressByArray(
    programDetail,
    from_to,
    contractReferralAddress
  )
  console.log(JSON.stringify(joinersAddress.map(addr => addr && addr[0])))
  */
  /* const joinersTemp = [
    '0xF5e2BE3Cc5d32Fa1C53274C6e3bA266964097e17',
    '0x58E78124fe7cc061E1A9c05118379E72f0ed0621',
  ]
  const joinersAddressL1L2 = useGetRefereesLevel1L2_temp(
    programDetail,
    joinersTemp,
    contractReferralAddress
  )
  const temp = joinersAddressL1L2.map((it, index) => {
    if (it) {
      return [
        joinersTemp[index],
        formatUnits(it[0][0], 0),
        formatUnits(it[0][1], 0),
      ]
    } else return ['', 0, 0]
  })
  console.log(JSON.stringify(temp)) */

  return (
    <Box className={classes.boxMain}>
      {/* <div>
        {status === 'loading' ? (
          'Loading...'
        ) : status === 'error' ? (
          <span>Error: {error.message}</span>
        ) : (
          <>
            <div>
              <Button variant="contained" onClick={() => setProgramCode('1')}>
                Set Program Code 1
              </Button>
              {data &&
                data.data.items.map(post => (
                  <p key={post.address}>{post.address}</p>
                ))}
            </div>
            <div>{isFetching ? 'Background Updating...' : ' '}</div>
          </>
        )}
      </div> */}
      {account && (
        <Box className={classes.box}>
          <div>
            {' '}
            Your Wallet is {account}{' '}
            {isAdmin === true && (
              <Chip
                style={{ color: 'red' }}
                label={'Admin'}
                variant="warning"
              />
            )}
          </div>
          {/* {isAdmin && (
            <div className={classes.uid}>
              Your UID (fake): <b>{myUid}</b>
            </div>
          )} */}
          {allowJoinProgram && isAdmin && (
            <div style={{ marginTop: 15 }}>
              <TextField
                label="Your UID (in rada.network)"
                id="outlined-size-small"
                size="small"
                value={myUid}
                onChange={e => setMyUid(e.target.value)}
              />
              <TextField
                label="Referral Code"
                id="outlined-size-small"
                size="small"
                value={myUidReferralCode}
                onChange={e => setMyUidReferralCode(e.target.value)}
              />
            </div>
          )}
          {account && joined && (
            <div>
              Link referral: <br />
              <b>
                {config.appUrl}/?ref={myUid}
              </b>
            </div>
          )}
        </Box>
      )}
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />

      <Box className={classes.box}>
        <h3>Your Balance</h3>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}>
          {supportedTokens.map((token, index) => {
            return (
              <Box
                key={`your-balance-${index}`}
                sx={{
                  borderRadius: 1,
                  flexGrow: 1,
                }}>
                <WalletBalance token={supportedTokens[index]} />
              </Box>
            )
          })}
        </Box>
        <h3>Referral Contract Balance</h3>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}>
          {supportedTokens.map((token, index) => {
            return (
              <Box
                key={`contract-balance-${index}`}
                sx={{
                  borderRadius: 1,
                  flexGrow: 1,
                }}>
                <ContractBalance
                  token={supportedTokens[index]}
                  account={contractAdminAddress}
                />
              </Box>
            )
          })}
        </Box>
      </Box>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />

      {isAdmin && (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton> */}
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                Projects
              </Typography>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  value={programCode}
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={e => setProgramCode(e.target.value.toUpperCase())}
                />
              </Search>
            </Toolbar>
          </AppBar>
        </Box>
      )}

      {Object.keys(programDetail).length > 0 && programDetail.code !== '' && (
        <Card sx={{ minWidth: 275, marginTop: 3 }}>
          <CardContent>
            {/* {calculatePage > 0 && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )} */}
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}>
              <Typography align="left" variant="h5" component="div">
                #{programDetail.code}
              </Typography>
              <Typography align="left" component="div">
                Status: {programDetail.paused ? 'Stopped' : 'Running'}
              </Typography>

              <TextField
                label="Limit per page"
                id="standard-size-small"
                value={limitPage}
                onChange={handleChangeLimit}
                size="small"
                variant="outlined"
                style={{ width: 100 }}
              />
              {currentPage > 0 && (
                <>
                  <Button
                    disabled={currentPage <= 1}
                    variant="contained"
                    onClick={handleReset}>
                    Reset
                  </Button>
                  {/* <Button
                    disabled={currentPage <= 1}
                    variant="contained"
                    onClick={handlePrevPage}>
                    Prev
                  </Button> */}
                  <Button
                    disabled={
                      Math.ceil(totalHolders / limitPage) <= currentPage
                    }
                    variant="contained"
                    onClick={handleNextPage}>
                    Next page {currentPage}/
                    {Math.ceil(totalHolders / limitPage)}
                  </Button>
                </>
              )}
              {currentPage === 0 && (
                <Button variant="contained" onClick={handleNextPage}>
                  Get Data
                </Button>
              )}
              {false && calculatePage === 0 && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => handleStartCalculate()}>
                    Calculate Incentive
                  </Button>
                  <TextField
                    label="Size"
                    id="standard-size-small"
                    value={limitPage}
                    onChange={handleChangeLimit}
                    size="small"
                    variant="outlined"
                  />
                </>
              )}
              <Typography align="left" component="div">
                Joiners: {totalHolders}
              </Typography>
              {/* {joinersAddress && joinersAddress.length > 0 && (
                <CSVLink data={joinersAddress.join('\n')}>
                  <DownloadIcon />
                </CSVLink>
              )} */}
            </Box>
            <Divider style={{ marginTop: 5 }} />
            <List dense>
              <ListItem>
                <ListItemText
                  primary="Max per referral"
                  secondary={formatUnits(programDetail.maxPerReferral, 18)}
                />
                <ListItemText
                  primary="Incentive level 1"
                  secondary={formatUnits(programDetail.incentiveLevel1, 18)}
                />
                <ListItemText
                  primary="Incentive level 2"
                  secondary={formatUnits(programDetail.incentiveLevel2, 18)}
                />
                {/* <ListItemText
                  primary="Incentive level 3"
                  secondary={formatUnits(programDetail.incentiveLevel3, 18)}
                /> */}
                <ListItemText
                  primary="Allocation Token"
                  secondary={formatUnits(programDetail.tokenAllocation, 18)}
                />
                <ListItemText
                  primary="Amount Incentive"
                  secondary={formatUnits(
                    programDetail.tokenAmountIncentive,
                    18
                  )}
                />
                <ListItemText
                  primary="Hold Incentive"
                  secondary={formatUnits(programDetail.incentiveAmountHold, 18)}
                />
              </ListItem>
            </List>
            <div sx={{ overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 450 }} component={Paper}>
                <Table stickyHeader aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Address ({holders.length}){' '}
                        {holders && holders.length > 0 && (
                          <CSVLink
                            data={holders
                              .map(row =>
                                [
                                  row.uid,
                                  row.address,
                                  row.incentivePaid,
                                  row.incentiveRemain,
                                  row.refL1,
                                  row.refL2,
                                ].join(',')
                              )
                              .join('\n')}>
                            <DownloadIcon />
                          </CSVLink>
                        )}
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="a"
                          target="_blank"
                          href={`https://bscscan.com/address/${contractReferralAddress}#readProxyContract`}>
                          <FactCheckIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">UID</TableCell>
                      <TableCell align="right">Paid</TableCell>
                      <TableCell align="right">Hold</TableCell>
                      <TableCell align="right">Ref L1</TableCell>
                      <TableCell align="right">Ref L2</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(isFetching || holders.length === 0) && (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}>
                        <TableCell
                          colSpan="7"
                          scope="row"
                          height={450}
                          align="center">
                          {isFetching ? 'Loading...' : 'No holders'}
                        </TableCell>
                      </TableRow>
                    )}
                    {!isFetching &&
                      holders.map((row, index) => (
                        <TableRow
                          key={`${row.address}-${index}`}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}>
                          <TableCell component="th" scope="row">
                            <a
                              style={{
                                textDecoration: 'none',
                                color: '#1976d2',
                              }}
                              target="_blank"
                              href={`https://bscscan.com/address/${row.address}`}
                              variant="text">
                              {row.address}
                            </a>

                            {row.banned === 1 && (
                              <Chip
                                style={{ color: 'red' }}
                                label={'BANNED'}
                                variant="danger"
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color:
                                row.totalIncentive >= 1 &&
                                (row.refL2 <= row.refL1 || row.pointValid < 5)
                                  ? 'red'
                                  : 'black',
                            }}>
                            {row.uid}
                          </TableCell>
                          <TableCell align="left">
                            {row.incentivePaid}
                          </TableCell>
                          <TableCell align="left">{row.remainAmount}</TableCell>
                          <TableCell align="right">
                            <Button
                              variant="text"
                              onClick={() =>
                                handleClickOpenListUser(row.address)
                              }>
                              {row.refL1}
                            </Button>
                          </TableCell>
                          <TableCell align="right">{row.refL2}</TableCell>
                          <TableCell align="right">
                            {!row.banned && (
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={handleClickOpenConfirm(
                                  row.address,
                                  index
                                )}>
                                <RemoveCircleRoundedIcon
                                  style={{ color: 'red' }}
                                />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CardContent>
          <CardActions className={classes.rightAlignItem}>
            {/* <Button variant="contained" onClick={handleClickOpenRemove}>
              Remove
            </Button> */}

            <Button
              disabled={
                holders.length === 0 ||
                approveAllState.status === 'Mining' ||
                totalIncentiveRemain === 0
              }
              variant="contained"
              onClick={handleClickOpenConfirmApprove}>
              Approve ({totalIncentiveRemain} RIR)
              {approveAllState.status === 'Mining' &&
                ', please wait (15 -> 30s)...'}
            </Button>
          </CardActions>
        </Card>
      )}

      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={openUsers}
        onClose={handleClickCloseListUser}
        scroll={'paper'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          Referees of {userAddress}
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer sx={{ maxHeight: 450 }} component={Paper}>
            <Table stickyHeader aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>UID</TableCell>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="left">Balance</TableCell>
                  <TableCell align="left">Transactions</TableCell>
                  <TableCell align="left">L1</TableCell>
                  <TableCell align="left">L2</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refereesAddress && refereesAddress.data.items.length === 0 && (
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    <TableCell component="th" scope="row">
                      No Referees
                    </TableCell>
                  </TableRow>
                )}
                {refereesAddress &&
                  refereesAddress.data.items.map((item, index) => {
                    return (
                      <TableRow
                        key={`${item.address}-${index}`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}>
                        <TableCell component="th" scope="row">
                          {item.uid}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            component="a"
                            target="_blank"
                            href={`https://bscscan.com/address/${item.address}`}
                            variant="text">
                            {item.address}
                          </Button>
                        </TableCell>
                        <TableCell align="left">{item.balanceOf}</TableCell>
                        <TableCell align="left">
                          {item.total_transactions}
                        </TableCell>
                        <TableCell align="left">{item.refereesL1}</TableCell>
                        <TableCell align="left">{item.refereesL2}</TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseListUser}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">BAN #{userAddress}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will clean all incentive of user as you see on screen.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={denyState.status === 'Mining'}
            onClick={handleCloseConfirm}>
            Disagree
          </Button>
          {!allowDenyMetaMask && (
            <Button
              disabled={isLoadingDeny}
              variant="contained"
              onClick={handleDeny}
              autoFocus>
              Deny commit Off-chain
            </Button>
          )}
          {allowDenyMetaMask && (
            <Button
              disabled={denyState.status === 'Mining'}
              variant="contained"
              onClick={handleConfirmDenyMetamask}
              autoFocus>
              Deny On-chain
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmApprove}
        onClose={handleCloseConfirmApprove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Approve all incentive</DialogTitle>
        <DialogContent>
          <TableContainer sx={{ maxHeight: 450 }} component={Paper}>
            <Table stickyHeader aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="left">Pay Incentive</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {holders &&
                  holders.map((item, index) => {
                    return (
                      <TableRow
                        key={`${item.address}-${index}`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}>
                        <TableCell component="th" scope="row">
                          {item.address}
                        </TableCell>
                        <TableCell align="left">{item.remainAmount}</TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={approveAllState.status === 'Mining'}
            onClick={handleCloseConfirmApprove}>
            Close
          </Button>
          {!allowApproveMetaMask && (
            <Button
              disabled={isLoadingCommit}
              variant="contained"
              onClick={handleCommit}
              autoFocus>
              Commit Off-chain
            </Button>
          )}
          {allowApproveMetaMask && (
            <Button
              disabled={approveAllState.status === 'Mining'}
              variant="contained"
              onClick={handleApprovalMetamask}
              autoFocus>
              Approve On-Chain ({totalIncentiveRemain} RIR)
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertMessage !== ''}
        autoHideDuration={10000}
        onClose={handleClose}
        message={alertMessage}
      />
    </Box>
  )
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}))

YourWallet.propTypes = {
  supportedTokens: PropTypes.array,
}
